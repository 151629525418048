/* eslint-disable no-useless-escape */

const availableItemsForElementsMapping = {
  interstitial: "dfp-interstitial",
  top: "dfp-masthead",
  sky: "dfp-skyscraper",
  1: "dfp-atf",
  "1-hybrid": "dfp-atf",
  2: "dfp-mtf",
  "2-hybrid": "dfp-mtf",
  3: "dfp-btf",
  "3-hybrid": "dfp-btf",
  footer: "dfp-bottompage",
  stickyfooter: "dfp-stickyfooter",
  webinterstitial: "dfp-webinterstitial",
};

const configurations = {
  a: [
    "top",
    "sky",
    "1-hybrid",
    "2-hybrid",
    "3-hybrid",
    "footer",
    "interstitial",
    "stickyfooter",
    "webinterstitial",
  ],
  b: [
    "top",
    "sky",
    "1-hybrid",
    "2-hybrid",
    "3-hybrid",
    "footer",
    "interstitial",
    "stickyfooter",
    "webinterstitial",
  ],
  c: [
    "top",
    "footer",
    "sky",
    "interstitial",
    "stickyfooter",
    "webinterstitial",
  ],
  d: [
    "top",
    "sky",
    "footer",
    "interstitial",
  ],
  e: [
    "top",
    "1",
    "2-hybrid",
    "3",
    "footer",
    "interstitial",
    "stickyfooter",
    "webinterstitial",
  ],
  f: [
    "top",
    "1-hybrid",
    "2-hybrid",
    "footer",
    "interstitial",
    "stickyfooter",
    "webinterstitial",
  ],
  g: [
    // trailer page
    "interstitial",
  ],
  h: [
    // no ads
  ],
  i: [
    "top",
    "1-hybrid",
    "2-hybrid",
    "3-hybrid",
    "sky",
    "footer",
    "interstitial",
    "stickyfooter",
    "webinterstitial",
  ],
  j: [
    "top",
    "sky",
    "1",
    "2",
    "interstitial",
    "footer",
    "stickyfooter",
    "webinterstitial",
  ],
  k: [
    "top",
    "1-hybrid",
    "2-hybrid",
    "sky",
    "footer",
    "interstitial",
    "stickyfooter",
    "webinterstitial",
  ],
};

const siteRouteConfig = {
  /*
   * V1 Pages
   */
  homepage: "j",
  //
  quickratings: "d",
  //
  lists: "d",
  list_page: "d",
  //
  blogs: "h",
  //
  vod: "a",
  /*
   * V2 pages
   */
  movie_index: "b", // V3
  movie_page: "b",
  //
  series_index: "b",
  series_page: "b",
  season_page: "b",
  serie_episodes: "b",
  //
  movie_casts: "k",
  serie_casts: "k",
  //
  movie_trivias: "d",
  /*
   * V2 / V3 item pages
   */
  article_comments: "d",
  serie_comments: "d",
  movie_comments: "d",
  //
  serie_vod: "c",
  movie_vod: "c",
  //
  serie_dvd: "d",
  movie_dvd: "d",
  person_dvd: "d",
  //
  serie_images: "d",
  serie_image: "d",
  movie_images: "d",
  movie_image: "d",
  person_images: "d",
  person_image: "d",
  //
  serie_trailer: "g",
  movie_trailer: "g",
  person_trailer: "g",
  //
  serie_lists: "d",
  movie_lists: "d",
  person_lists: "d",
  //
  serie_news: "f",
  movie_news: "f",
  person_news: "f",
  //
  person_filmography: "i",
  /*
   * V3 pages
   */
  support_index: "d",
  support_show: "d",
  //
  people_index: "k",
  people_page: "k",
  //
  news: "i",
  article_page: "e",
  news_themes: "i",
  news_theme_page: "i",
};

const dfpGetUrlParameter = (name) => {
  name = name.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
  const regexS = `[\\?&]${name}=([^&##\(\);)]*)`;
  const regex = new RegExp(regexS);
  const results = regex.exec(window.location.href);
  return results == null ? "" : results[1];
};

const dataLayer = window.dataLayer || [];
const siteSection = window.dataLayer[0].site_section !== undefined ? dataLayer[0].site_section : "other";
const siteRoute = dataLayer[0].site_route !== undefined ? dataLayer[0].site_route : "";

const dfpDomain = "DE_Moviepilot";
const dfpshowroom = dfpGetUrlParameter("showroom").replace("showroom", "Showroom");
const page = `/35853227/${dfpshowroom.length ? dfpshowroom : `${dfpDomain}/`}${dfpshowroom.length ? "" : `${siteSection}/${siteRoute}`}`;

const s = document.createElement("script");
s.async = true;
s.src = `https://cdn.lib.getjad.io/library/35853227/${dfpshowroom.length ? "Showroom" : dfpDomain}`;
s.setAttribute("importance", "high");
const g = document.getElementsByTagName("head")[0];
g.parentNode.insertBefore(s, g);

const createElementsMapping = (pagePositions = []) => {
  const elementsMapping = {};
  pagePositions.forEach((item) => {
    if (Object.prototype.hasOwnProperty.call(availableItemsForElementsMapping, item)) {
      elementsMapping[item] = availableItemsForElementsMapping[item];
    }
  });
  return elementsMapping;
};

const createPagePositions = (siteRouteForJad) => {
  let pagePositions = configurations[siteRouteForJad];

  if (document.getElementById("dfp-bottompage") === null) {
    pagePositions = pagePositions.filter(item => item !== "footer");
  }

  return pagePositions;
};

export const createJadConfig = () => {
  const dynamicSiteRouteOfSiteSectionsA = ["showtimes", "dvd"];
  const dynamicSiteRouteOfSiteSectionsB = ["tv", "broadcast", "community"];

  let siteRouteForJad;
  if (Object.prototype.hasOwnProperty.call(siteRouteConfig, siteRoute)) {
    siteRouteForJad = siteRouteConfig[siteRoute];
  } else if (dynamicSiteRouteOfSiteSectionsA.includes(siteSection)) {
    // showtimes & dvd
    siteRouteForJad = "i";
  } else if (dynamicSiteRouteOfSiteSectionsB.includes(siteSection)) {
    // tv & broadcast
    siteRouteForJad = "d";
  } else {
    siteRouteForJad = "j";
  }

  const pagePositions = createPagePositions(siteRouteForJad);

  const targeting = Object.assign({}, window.dataLayer[0]);

  if (window.dataLayer[0].linked_entities !== undefined
    && Object.prototype.hasOwnProperty.call(window.dataLayer[0].linked_entities, "tags")) {
    targeting.tags = window.dataLayer[0].linked_entities.tags;
    delete targeting.linked_entities;
  }

  if (dfpshowroom.length) {
    targeting.sh_targeting = dfpGetUrlParameter("targeting") || "";
  }

  if (targeting.genre) targeting.genre = targeting.genre.map(String);

  return {
    page,
    elementsMapping: createElementsMapping(pagePositions),
    interstitialOnFirstPageEnabled: true,
    pagePositions,
    autoRefreshEnabled: true,
    targeting,
  };
};
