/* eslint-disable import/first */
/* eslint-disable no-undef */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */

import { createJadConfig } from "../jad/setup";

if (typeof window !== "undefined" && window !== null) {
  window.jad = window.jad || {};
  jad.cmd = jad.cmd || [];

  window.jadConfig = createJadConfig();

  jad.cmd.push(() => {
    jad.public.setConfig(jadConfig);
    jad.public.loadPositions();
    jad.public.displayPositions();
  });
}
